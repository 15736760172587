import React, { useState } from "react";
import "./index.scss";
import { Button, Form, Input, message } from "antd";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Base64 } from "js-base64";
import pwdImg from "../../../assets/images/login/pwd.png";
import userImg from "../../../assets/images/login/user.png";
import { PlatformLogin } from "../../../api/modules/common/interface";
import { CommonAPI } from "../../../api";
// eslint-disable-next-line import/no-cycle
import { router } from "../../../router";
import ProjectModal from "./components/projectModal";
import { GetRouteIDList, getLeafRoute } from "../../../utils/router";
import { SET_MENU } from "../../../store/module/menu";
import {
  applicationEntity,
  projectEntity,
} from "../../../api/modules/superManage/interface";
import { ResponseType } from "../../../api/interface";
import { SET_USERINFO } from "../../../store/module/userInfo";

function LoginView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginForm] = Form.useForm<PlatformLogin>();

  const [visible, setVisible] = useState<boolean>(false);
  // 当前用户项目列表
  const [projectList, setProjectList] = useState<
    (Pick<projectEntity, "name" | "id" | "appId"> &
      Pick<applicationEntity, "url" | "manageUrl" | "type">)[]
  >([]);
  // 拷贝一份->search
  const [projectListClone, setProjectListClone] = useState<
    (Pick<projectEntity, "name" | "id" | "appId"> &
      Pick<applicationEntity, "url" | "manageUrl" | "type">)[]
  >([]);

  // 当前用户权限
  const getUserPermission = async (id?: number) => {
    const res: ResponseType<any[]> = await CommonAPI.getManagePermission(id);
    if (res.data && res.data.length !== 0) {
      dispatch(SET_MENU(res.data));
      localStorage.setItem("TMJ-ADMIN-MENU-LIST", JSON.stringify(res.data));
      if (id) localStorage.setItem("TMJ-ADMIN-MENU-ID", JSON.stringify(id));
      const routeList = getLeafRoute(GetRouteIDList(res.data), router);
      message.success("登录成功");
      navigate(routeList[0].path);
    } else {
      message.info("请先配置权限！");
    }
  };
  // 获取用户项目列表
  const getProjectListByUser = async () => {
    const res: ResponseType<
      (Pick<projectEntity, "name" | "id" | "appId"> &
        Pick<applicationEntity, "url" | "manageUrl" | "type">)[]
    > = await CommonAPI.getProjectListByUser();
    if (res.data && res.data.length !== 0) {
      setProjectList(res.data || []);
      setProjectListClone(res.data || []);
      setVisible(true);
    } else {
      const userInfo: any = window.localStorage.getItem("TMJ-ADMIN-USER-INFO");
      const userInfoObj = JSON.parse(userInfo);
      console.log(userInfoObj, "userInfoObj");
      if (userInfoObj.roleType === 4) {
        message.warning("此账号为推广账号，无后台管理权限");
      } else {
        message.warning("该账号下不存在项目");
      }
    }
  };
  // 登录
  const loginClick = () => {
    loginForm
      .validateFields()
      .then(async (values: any) => {
        const formData = new FormData();
        Object.keys(values).forEach((key: string) => {
          formData.append(
            key,
            key === "password" ? Base64.encode(values[key]) : values[key]
          );
        });
        try {
          const res: any = await CommonAPI.platformLogin(formData);
          if (res.code === 200) {
            // 存token
            Cookies.set("TIANMU_TOKEN", res.data.token);
            // 存用户信息
            dispatch(SET_USERINFO(res.data));
            if (res.data.roleType === 1) {
              await getUserPermission();
            } else {
              await getProjectListByUser();
            }
          }
        } catch (e) {
          console.log("登录失败", e);
        }
      })
      .catch((e) => {
        console.log("校验失败", e);
      });
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleOk = () => {
    setVisible(false);
  };
  // 项目跳转
  const toProjectPath = (id: number) => {
    const projectObj = projectList.find((v) => v.id === id);
    if (projectObj && projectObj.type !== "1") {
      window.open(projectObj.url, "_blank");
    } else {
      getUserPermission(id).then();
    }
    // getMenuList();
  };
  // 项目筛选
  const inputChange = (str: string) => {
    const list = projectListClone.filter((v) => v.name.includes(str));
    setProjectList(str ? list : projectListClone);
  };
  // 键盘事件
  const keyDownEvent = (e: any) => {
    if (e.keyCode === 13) {
      loginClick();
    }
  };
  return (
    <div className="login-wrap">
      <div className="head">天目将后台管理系统</div>
      <div className="login-content">
        <div className="login-card">
          <div className="left-bg" />
          <div className="center-bg">
            <div className="center-bg-wrap">
              <div className="title">登录</div>
              <div className="login-form">
                <Form form={loginForm}>
                  <Form.Item
                    name="userAccount"
                    rules={[
                      {
                        required: true,
                        message: "请输入用户名",
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入用户名"
                      prefix={
                        <img
                          style={{ width: "28px", height: "28px" }}
                          src={userImg}
                          alt=""
                        />
                      }
                      onKeyDown={keyDownEvent}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "请输入密码",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="请输入密码"
                      prefix={
                        <img
                          src={pwdImg}
                          style={{ width: "28px", height: "28px" }}
                          alt=""
                        />
                      }
                      onKeyDown={keyDownEvent}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" onClick={loginClick}>
                      登录
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
          <div className="right-bg" />
        </div>
        <div className="bottom-bg">
          <div className="line-bg" />
          <div className="system-copy">
            Copyright &copy;2009 - {new Date().getFullYear()}
            浙江科比特创新科技有限公司
          </div>
        </div>
      </div>
      <ProjectModal
        visible={visible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        toProjectPath={toProjectPath}
        projectList={projectList}
        inputChange={inputChange}
      />
    </div>
  );
}

export default LoginView;
